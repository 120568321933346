<template>
  <div v-if="canAccess('rp_students_country')" style=" background-color: #ffffff;
    padding: 1em;">
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
      <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>

    <div class="p-grid p-fluid dashboard">
      <div class="p-col-12" >
        <div class="p-text-right p-fluid  p-ml-2">
          <div class="p-grid p-fluid  p-ml-2">
            <div class="p-col-12 p-md-2 p-mt-2" style="padding-top: 20px;">
                                  <span class="p-float-label df" v-if="isAdmin" style="margin-right: 1px;">
                                    <Dropdown  id="country" v-model="country" :options="countries" optionLabel="country"
                                               @change="subsidiary=null;itemsTotal=[]"/>
                                    <label for="country">País</label>
                                </span>
            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
                      <span class="p-float-label df" >
                          <Dropdown id="subsidiary" v-model="subsidiary" :options="subsidiaries"
                                    @change="itemsTotal=[]"
                                    optionLabel="name"/>
                          <label for="subsidiary">Filial</label>
                      </span>
            </div>
            <div  style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
            <span class="p-float-label df" style="margin-right: 3px">
                          <Calendar autocomplete="off" :maxDate="new Date()" aria-autocomplete="false"
                                    id="start_date" :showIcon="true" v-model="date_start"
                                    dateFormat="yy-mm-dd"
                                    :yearNavigator="true" yearRange="2019:2040"/>
                          <label for="start_date">Fecha Inicio</label>
            </span>
            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
              <span class="p-float-label df" style="margin-left: 3px">
                  <Calendar :maxDate="new Date()" :minDate="date_start" autocomplete="off"
                            id="end_date" :showIcon="true" v-model="date_end" dateFormat="yy-mm-dd"
                            :yearNavigator="true" yearRange="2019:2040" class="p-inputtext-sm"/>
                  <label for="end_date">Fecha Fin</label>
              </span>
            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
              <Button label="Buscar"  class="p-button-success  df"
                      @click="getData()"/>
            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
              <Button label="Exportar"  class="p-button-secondary df"
                      @click="exportReport('XLS')"/>

            </div>  <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
            <Button label="Imprimir" class="p-button-info df"
                    @click="exportReport('PDF')"/>

          </div>

          </div>
        </div>
      </div>
    </div>

    <div class="p-grid p-fluid dashboard" style="padding: 10px;background: white" v-for="(itemsSub,idx) in itemsTotal " :key="'tb'+idx">
      <div class="card card-w-title" id="divCard" style="padding:0px;min-width: 340px;width: 100%; overflow-x: auto;">
        <div class="tableC">
          <DataTable :value="itemsSub" style="margin-top: 20px;">
            <ColumnGroup type="header">
              <Row>
                <Column style="padding: 6px!important;"
                        :header="' - FILIAL: '+idx"
                        colspan="8"></Column>
              </Row>
              <Row>
                <Column header="Matrícula" headerClass="thReport"/>
                <Column header="Nombre Titular"  headerClass="thReport"/>
                <Column header="Cargo" headerClass="thReport"/>
                <Column header="Ciudad" headerClass="thReport"/>
                <Column header="Dirección" headerClass="thReport"/>
                <Column header="Estudiantes" headerStyle="width: 390px"/>
                <Column header="Ingreso Titular" headerClass="thReport"/>
                <Column header="Es Estudiante" headerClass="thReport"/>
              </Row>
            </ColumnGroup>


            <Column field="matricula" bodyClass="tdReport"/>
            <Column field="titular" bodyClass="tdReport"/>
            <Column field="position" bodyClass="tdReport"/>
            <Column field="ciudad" bodyClass="tdReport"/>
            <Column field="direccion" bodyClass="tdReport"/>
            <Column field="estudiantes" bodyClass="tdReport">
              <template #body="slotProps">
                <div v-if="slotProps.data.estudiantes !== null">
                  <div v-for="(estudiante, index) in slotProps.data.estudiantes.split(',')" :key="'es'+estudiante">
                    <label>{{ index+1 }}.- {{ estudiante.split('->')[0] }}</label> <label>({{ estudiante.split('->')[1] }})</label>
                  </div>
                </div>
              </template>
            </Column>
            <Column field="ingreso_mensual" bodyClass="tdReport"/>
            <Column field="is_student" bodyClass="tdReport">
              <template #body="slotProps">
                {{ slotProps.data.is_student == 'y' ? "Si" : "No" }}
              </template>
            </Column>





          </DataTable>
        </div>
      </div>
    </div>

  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>


<script>
import service from '@/modules/enrollment/service/student.service';
import {mapGetters, mapState} from "vuex";
import bouncer from "../../../helpers/bouncer";
import FileSaver from 'file-saver';
import moment from "moment";
import catalogService from "@/modules/core/service/catalog.service";

export default {
  mixins: [bouncer],
  name: "EnrollReport",
  data() {
    return {
      date_end:null,
      date_start:null,
      country: [],
      subsidiary: [],
      countries: [],
      items: [],
      itemsTotal: [],
      itemsDetails: [],
      total: [],
      loading: false,
      submitted: false
    }
  },
  mounted() {
    if (this.canAccess('rp_students_country')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Estadísticas', url: "javascript:void(0);"},
        {label: 'Informe de estudiantes por país', url: "javascript:void(0);"},
      ]);
    }
    catalogService.get(`select/countries/subsidiaries`).then(response => {
      this.countries = response.data;
      this.loading = false;
      if (!this.isAdmin) {
        this.country = this.countries[0];
      }
    });
  },
  methods: {
    calculateRegTotal(name) {
      let total = 0;
      if (this.items) {
        for (let customer of this.items) {
          if (customer.subsidiaria === name) {
            total++;
          }
        }
      }
      return total;
    },
    onPage(data) {
      this.getData(data.page+1);
    },

    getData(page=1) {

      if (!moment(this.date_start).isValid() || !moment(this.date_end).isValid() ||
          Object.entries(this.country).length === 0 || Object.entries(this.subsidiary).length === 0) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Los campos de fecha, país y subsidiaria son obligatorios para el reporte',
          life: this.$utils.toastLifeTime()
        });
      } else {
        if (moment(this.date_start) > moment(this.date_end)) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'El campo fecha hasta debe ser mayor o igual que el campo fecha desde',
            life: this.$utils.toastLifeTime()
          });
        } else {
          this.loading = true;

          let params = {};
          params.page = page;
          params.country = this.country.id;
          params.subsidiary = this.subsidiary.id;
          params.date_start = moment(this.date_start).format("YYYY-MM-DD");
          params.date_end = moment(this.date_end).format("YYYY-MM-DD");

          service.getStudentsByCountry(params).then(response => {
            this.itemsTotal = response.data;
            this.loading = false;
          });
        }
      }
    }
    ,
    exportReport(command) {
      this.loading = true;

      let params = {};

      params.country = this.country.id;
      params.subsidiary = this.subsidiary.id;
      params.date_start = moment(this.date_start).format("YYYY-MM-DD");
      params.date_end = moment(this.date_end).format("YYYY-MM-DD");
      params.export = command;

      service.getStudentsByCountry(params)
          .then(x => {

            if(command=='PDF'){
              this.info = x.data;
              var byteCharacters = atob(x.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], {type: "application/pdf"});

              FileSaver.saveAs(blob, "EstudiantesPorPais.pdf");
            }else{
              window.open(x.data.url_file)
            }

          }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      }).finally(() => this.loading = false)

    }
    ,
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
    ...mapGetters('bread', ['breadcrumb']),
    subsidiaries() {
      var listSubsidiaries= this.country ? this.country.subsidiaries : [];
      var elements=[];
      if((typeof listSubsidiaries !=='undefined')){
        var obj={
          id: null,
          employee_id: 0,
          city_id: listSubsidiaries[0].city_id,
          name: "Todas las Filiales",
          created_at: null,
          deleted_at: null,
          laravel_through_key: listSubsidiaries[0].laravel_through_key,
          updated_at: null};
        elements=[obj,...listSubsidiaries]
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.subsidiary=obj;
      }
      return elements;
    }
  }
}
</script>

<style>

.p-dropdown .p-dropdown-trigger {
  height: 33px;
}

.thReport {

  padding: 2px !important;
  border-width: 1px !important;
}

.tdReport {
  padding: 2px !important;
  border-width: 1px !important;
}

div.tableC>div>div>table{
  table-layout: auto !important;
}

.df{
  margin-left: 3px!important;
  margin-right: 4px!important;

}

span.p-calendar.p-component.p-inputwrapper.p-calendar-w-btn.p-inputwrapper-filled{
  height: 35px!important;
}

.p-dropdown-label{
  text-align: left;
}
</style>
